import { Component, OnInit } from '@angular/core';
import { MenuService } from '../services/menu.service';

@Component({
  //moduleId: module.id,
  selector: 'itemdetails',
  templateUrl: 'itemdetails.component.html',
  providers: [MenuService]
})
export class MenuItemDetailsComponent implements OnInit {
  Item;

  constructor(private menuService: MenuService){
    console.log("Init itemDetails");
    this.Item = MenuService.selectedMenuItem;
    console.log(this.Item);
  }
  ngOnInit() {
    // console.log("nginit...itemDetails");
    // this.Item = this.menuService.selectedMenuItem;
    // console.log(this.Item);
  }
}
