import { Component } from '@angular/core';

@Component({
  //moduleId: module.id,
  selector: 'zmenu',
  templateUrl: 'menu.component.html'
})
export class MenuComponent {
  ngOnInit() {
    let showMenu = document.querySelector("#show-menu") as HTMLInputElement;
    showMenu.checked = false;
  }
}
