import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.css']
})
export class PhotogalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let showMenu = document.querySelector("#show-menu") as HTMLInputElement;
    showMenu.checked = false;
  }

}
