<div class="contact-us">
      <span class="lbl">Address:</span>
      <span>597 Boul. Cote Vertu, St. Laurent, QC, H4L 1X8</span>
      <br><br>
      <span class="lbl">Phone:</span>
      <span>438-795-8505</span>
      <br><br>
      <span class="lbl">Email:</span>
      <span>info@chezwafi.com</span>
      <br><br>
      <span class="lbl">Opening Hours:</span>
      <span><!--7 Days a week,--><br>
                  <div style="padding-left: 4em;">
                          Sundays from 9:30 AM to 2:30 PM.<br>
                          Mondays CLOSED.<br>
                          Tuesdays - Thursdays from 9:30 AM to 3:30 PM.<br>
                          Fridays - Saturdays from 9:30 AM to 10:00 PM.
                  </div>
      </span>
</div>
<br/>
<br/>
<br/>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.107109435054!2d-73.67516365334546!3d45.522668139227264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9185d403af11b%3A0x7c63612a4fb235d!2sChez+Wafi!5e1!3m2!1sen!2sca!4v1464890611873" width="100%" height="450" class="google-map-iframe" frameborder="0" style="border:0" allowfullscreen></iframe>
