<div>
  <div class="menuTxt-btn-container">
    <a routerLink="/menutxt" class="menuTxt-btn">Search The Menu</a>
  </div>

  <div class="logo-container">
      <img src="assets/img/chezwafiMenu/Menu1.jpg" alt="Menu1">
      <img src="assets/img/chezwafiMenu/Menu2.jpg" alt="Menu2">
      <img src="assets/img/chezwafiMenu/Menu3.jpg" alt="Menu3">
      <img src="assets/img/chezwafiMenu/Menu4.jpg" alt="Menu4">
  </div>
</div>
