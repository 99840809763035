<div class="about-us">
      We remember when we were children; we used to eat the kaakeh with Picon or La Vache Qui Rit

      (the laughing cow) cheese, or sprinkle inside the pocket Zaatar Mix (Oregano) or

      Sumac.Moreover,The Kaakeh is a necessity for  ninety percent of the Lebanese community when they come

      back to Montreal from Lebanon.

      Finally Kaakeh has arrived to Canada !!!

      <h2>Our Commitments:</h2>
      <ul>
        <li>
          To uphold the highest professional standards and continue to improve them to ensure efficiency and consistency.
        </li>
        <li>
          To build a lasting, life-long relationship with our clients based on respect, service, flavor, value and pleasure.
        </li>
        <li>
          To contribute positively to economic growth by creating job opportunities and by supporting educational, social and environmental activities.
        </li>
        <li>
          To provide the best quality while adhering to the highest food safety standards.
        </li>
      </ul>
      <h2>We Believe in:</h2>

      <h3>Operations Excellence:</h3>

      We embrace our core pillars: Quality, cleanness and value for our customers, while
      developing our team and seeking continuous improvement through observation.

      <h3>Leadership:</h3>

      We inspire our Staff to drive the &#34;customer Business&#34; forward, through initiating change,
      building teamwork and honoring our obligations.

      <h3>Commitment:</h3>

      We persistently strive to achieve our goals and fulfill our customers&#39; expectations while

      above all, adhering to our principles.

      <h3>Accountability:</h3>

      We take hundred percent responsibility action and outcome and deliver what we promised with zero

      excuses.

      <h3>Growth:</h3>
      We pursue the development of our employees and seize all opportunities that lead us to

      grow substantially in market share and profitability.

      <h3>Integrity:</h3>
      We serve our Customers and employees under the guidance of strong moral beliefs that set

      the boundaries for our actions.

      <h2>Our identity:</h2>

      Different presentation, Traditional Taste.

      We wanted Chez Wafi to have a colorful and cozy environment. You know, the kind of place you&#39;d go

      with your friends on your way to college or on the way back from the movies.

      We realize we are also where you wanted to come on a date, to treat your family and maybe even to

      seal a business deal. So that got us to think about how we should look.

      We enjoyed being young and casual. So here we are today. The stone and wood walls with colorful

      tables have given way to a smarter, more simple, urban look.

      Our stuff is friendly. Our food is fantastic. And our doors are open 7 days a week from 6 AM to 10 PM.

      More than a bakery, we&#39;ve designed to suit the urban and casual life style of today. Just like you.

      <h2>Sharing is caring:</h2>

      At Chez Wafi, we&#39;re always on the move. Get closer, catch up with our news, share your favorite

      moments and photos.
    </div>
