<div class="gallery-container">
    <img src="assets/img/photogallery/MenuWafi13672.jpg" alt="Menu">
    <img src="assets/img/photogallery/Basterma.jpg" alt="Basterma">
    <img src="assets/img/photogallery/Chicken.jpg" alt="Chicken">
    <img src="assets/img/photogallery/Chocolat.jpg" alt="Chocolat">
    <img src="assets/img/photogallery/Minis.jpg" alt="Minis">
    <img src="assets/img/photogallery/Omelette.jpg" alt="Omelette">
    <img src="assets/img/photogallery/Saumon.jpg" alt="Saumon">
    <img src="assets/img/photogallery/Steak.jpg" alt="Steak">
    <img src="assets/img/photogallery/Turkey.jpg" alt="Turkey">
</div>