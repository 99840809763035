
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


import { MenuItem } from '../components/item';

@Injectable()
export class MenuService{
  public static selectedMenuItem: MenuItem;
  constructor(private httpClient: HttpClient){
    console.log("init Service...");
    console.log(MenuService.selectedMenuItem);
    console.log("init Service End...");
  }
  // ngOnInit() {
  //   console.log("ng init Service...");
  //   // this.selectedMenuItem.id=0;
  //   // this.selectedMenuItem.Category = "test";
  //   // this.selectedMenuItem.Name = "test";
  //   // this.selectedMenuItem.Price = "test";
  //   // this.selectedMenuItem.Ingredients = "test";
  //   console.log(this.selectedMenuItem);
  //   console.log("ng init Service End...");
  // }
  getMenu(){
    console.log("Loading Menu...");
    return this.httpClient.get("assets/data/menu.json");//.pipe(map(res => res.json()));
  }
  // setSelectedMenuItem(selectedMenuItem){
  //   this.selectedMenuItem = selectedMenuItem;
  //   console.log("Setting" + this.selectedMenuItem);
  // }
  // getSelectedMenuItem(){
  //   console.log("getting" + this.selectedMenuItem);
  //   return this.selectedMenuItem;
  // }
}
