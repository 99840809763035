import { Component } from '@angular/core';

@Component({
  //moduleId: module.id,
  selector: 'aboutus',
  templateUrl: 'aboutus.component.html'
})
export class AboutusComponent {
  ngOnInit() {
    let showMenu = document.querySelector("#show-menu") as HTMLInputElement;
    showMenu.checked = false;
  }
}
