<div class="menu-txt-container">
  <div class="search-box-container">
    <div id='search-box'>
      <input type="text" placeholder='Menu Search' autofocus id="search-text" [(ngModel)]="query"/>
    </div>
  </div>

  <table class="table-fill">
    <thead>
      <tr>
        <th class="text-left">ID</th>
        <th class="text-left">Name</th>
        <th class="text-left">Price</th>
      </tr>
    </thead>
    <tbody class="table-hover">
      <!-- <tr ng-repeat="Item in menuData | filter:query">
        <td class="text-left" ng-click="openItem(Item)">{{Item.id}}</td>
        <td class="text-left" ng-click="openItem(Item)">{{Item.Name}}</td>
        <td class="text-left" ng-click="openItem(Item)">{{Item.Price}}</td>
      </tr> -->

      <tr *ngFor="let Item of menuData | filter:query">
        <td class="text-left" (click)="openItem(Item)">{{Item.id}}</td>
        <td class="text-left" (click)="openItem(Item)">{{Item.Name}}</td>
        <td class="text-left" (click)="openItem(Item)">{{Item.Price}}</td>
      </tr>

    </tbody>
  </table>
</div>
