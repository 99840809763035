
<div class="back-btn-container">
  <a routerLink="/menutxt" class="back-button">BACK</a>
</div>

<table class="table-fill">
  <tbody class="table-hover">
    <tr>
      <td class="text-left">ID</td>
      <td class="text-left">{{Item.id}}</td>
    </tr>
    <tr>
      <td class="text-left">Name</td>
      <td class="text-left">{{Item.Name}}</td>
    </tr>
    <tr>
      <td class="text-left">Category</td>
      <td class="text-left">{{Item.Category}}</td>
    </tr>
    <tr>
      <td class="text-left">Ingredients</td>
      <td class="text-left">{{Item.Ingredients}}</td>
    </tr>
    <tr>
      <td class="text-left">Price</td>
      <td class="text-left">{{Item.Price}}</td>
    </tr>
  </tbody>
</table>
