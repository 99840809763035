import { Component } from '@angular/core';

@Component({
  selector: 'home',
  template: '<div><img src="assets/img/Kaakeh.jpg" alt="Kaakeh"></div>'
  //template: '<div class="menuTxt-btn-container"><a href="https://chezwafi-betterresto.netlify.app/" target="blank" class="menuTxt-btn"><span>Order Online</span></a></div><div><img src="assets/img/Kaakeh.jpg" alt="Kaakeh"></div>'
})
export class HomeComponent {

  ngOnInit() {
    let showMenu = document.querySelector("#show-menu") as HTMLInputElement;
    showMenu.checked = false;
  }
}
