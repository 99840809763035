import { Component, OnDestroy } from '@angular/core';
import { Router} from '@angular/router';

import { MenuService } from '../services/menu.service';

@Component({
  //moduleId: module.id,
  selector: 'menutxt',
  templateUrl: 'menutxt.component.html',
  providers: [MenuService]
})

export class MenutxtComponent implements OnDestroy {
  menuData;// MenuItem[];
  menuItem;
  query: string;

  constructor(private menuService: MenuService, private router: Router){
    console.log("Init menutxt");
    this.menuService.getMenu().subscribe(menu => {
      //console.log(menu);
      this.menuData = menu;
      //console.log(this.menuData);
    });
  }
  ngOnInit() {
    let showMenu = document.querySelector("#show-menu") as HTMLInputElement;
    showMenu.checked = false;
  }

  openItem(menuItem){
    console.log(menuItem);
    //this.menuService.selectedMenuItem = menuItem;
    this.menuItem = menuItem;
    this.router.navigate(['/itemdetails']);
  }
  ngOnDestroy(){
    console.log("OnDestroy... " + this.menuItem);
    MenuService.selectedMenuItem = this.menuItem;
  }

}
