import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/navmenu.component';
import { HomeComponent } from './components/home.component';
import { MenuComponent } from './components/menu.component';
import { MenutxtComponent } from './components/menutxt.component'
import { MenuItemDetailsComponent } from './components/itemdetails.component';
import { PhotogalleryComponent } from './components/photogallery.component';
import { AboutusComponent } from './components/aboutus.component';
import { ContactusComponent } from './components/contactus.component';
import { FilterPipe } from './pipes/searchfilter';
import { routing } from './app.routing';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    MenuComponent,
    MenutxtComponent,
    MenuItemDetailsComponent,
    AboutusComponent,
    ContactusComponent,
    FilterPipe,
    PhotogalleryComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing
  ],
  providers: [],
  bootstrap: [AppComponent, NavMenuComponent]
})
export class AppModule { }
