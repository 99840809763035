import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { HomeComponent } from './components/home.component';
import { MenuComponent } from './components/menu.component';
import { PhotogalleryComponent } from './components/photogallery.component';
import { MenutxtComponent } from './components/menutxt.component';
import { MenuItemDetailsComponent } from './components/itemdetails.component';
import { AboutusComponent } from './components/aboutus.component';
import { ContactusComponent } from './components/contactus.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'menu',
    component: MenuComponent
  },
  {
    path: 'menutxt',
    component: MenutxtComponent
  },
  {
    path: 'itemdetails',
    component: MenuItemDetailsComponent
  },
  {
    path: 'photogallery',
    component: PhotogalleryComponent
  },
  {
    path: 'aboutUs',
    component: AboutusComponent
  },
  {
    path: 'contactUs',
    component: ContactusComponent
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
